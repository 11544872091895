import Api from '@/core/api'

export default {
	index(id) {
		return Api().get('applications', {
			params: {
				activity_id: id,
			},
		})
	},
	view(id) {
		return Api().get(`applications/${id}`)
	},
	create(data) {
		return Api().post('applications', data)
	},
	indexAttendance(id) {
		return Api().get('applications/attendance', {
			params: {
				activity_id: id,
			},
		})
	},
	indexPayment(id) {
		return Api().get('applications/payment', {
			params: {
				activity_id: id,
			},
		})
	},
}
