<template>
	<section :id="id">
		<div class="header">
			<div class="header-title-wrapper">
				<div class="chapter-circle">
					<span class="chapter-text">{{ chapter }}</span>
				</div>
				<h3 class="header-title">{{ title }}</h3>
			</div>
			<div class="cta-wrapper">
				<slot name="cta"/>
			</div>
		</div>

		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'ActivityApplicationSection',
	props: {
		id: String,
		title: String,
		chapter: [String, Number],
	},
}
</script>

<style scoped>
section {
	@apply bg-white;
}

.header {
	@apply flex justify-between items-center mb-20;
}

.header-title-wrapper {
	@apply flex items-center gap-5;
}

.header-title {
	@apply my-0;
}

.chapter-circle {
	@apply h-10 w-10 bg-primary rounded-full grid place-items-center;
}

.chapter-text {
	@apply text-white font-bold text-sm;
}

.cta-wrapper {
	@apply flex justify-end items-center gap-5;
}
</style>