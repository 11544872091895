<template>
	<main class="py-0">
		<form @submit.prevent="submitListener">
			<section id="chapters">
				<div class="flex items-center gap-5">
					<div v-for="(chap, key) in CHAPTERS" :key="key" class="chapter-wrapper" @click="changeChapter(chap)">
						<div class="chapter-circle" :class="{'active': chap === chapter}">
							<span class="chapter-text">{{ chapterNumber(chap) }}</span>
						</div>
						<div class="chapter-line" v-if="chapterNumber(chap) !== chaptersLength()"></div>
					</div>
				</div>
			</section>

			<ActivityApplicationSection id="personal" title="Personuppgifter" chapter="1" v-show="chapter === CHAPTERS.PERSONAL">
				<template #cta>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.LODGING)" :disabled="!isPersonalComplete">
						Nästa
					</BaseButton>
				</template>

				<div class="grid grid-cols-3 gap-5">
					<BaseInput type="text" v-model="personal.ssn" required maxlength="12">
						Personnummer
					</BaseInput>

					<BaseInput type="text" v-model="personal.first_name" required>
						Förnamn
					</BaseInput>

					<BaseInput type="text" v-model="personal.last_name" required>
						Efternamn
					</BaseInput>

					<BaseInput type="email" v-model="personal.email" required>
						E-postadress
					</BaseInput>

					<BaseInput type="phone" v-model="personal.telephone" required maxlength="10">
						Telefonnummer
					</BaseInput>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="lodging" title="Logi & Kost" chapter="2" v-show="chapter === CHAPTERS.LODGING">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.PERSONAL)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.INVOICE)">
						Nästa
					</BaseButton>
				</template>

				<div class="grid gap-5">
					<BaseTextarea v-model="lodging.lodging" rows="5">
						Logi
					</BaseTextarea>

					<BaseTextarea v-model="lodging.food" rows="5">
						Kost
					</BaseTextarea>

					<div class="grid grid-cols-4 gap-5">
						<div>
							<h5 class="mb-5">Diet</h5>

							<div class="flex flex-col gap-2">
								<div class="text-sm">
									<label for="vegan" class="flex items-center gap-2">
										<input type="checkbox" id="vegan" v-model="lodging.vegan">
										Vegan
									</label>
								</div>

								<div class="text-sm">
									<label for="vegetarian" class="flex items-center gap-2">
										<input type="checkbox" id="vegetarian" v-model="lodging.vegetarian">
										Vegetarian
									</label>
								</div>
							</div>
						</div>

						<div>
							<h5 class="mb-5">Allergi</h5>

							<div class="flex flex-col gap-2">
								<div class="text-sm">
									<label for="lactose" class="flex items-center gap-2">
										<input type="checkbox" id="lactose" v-model="lodging.lactose">
										Laktosintollerant
									</label>
								</div>

								<div class="text-sm">
									<label for="gluten" class="flex items-center gap-2">
										<input type="checkbox" id="gluten" v-model="lodging.gluten">
										Glutenintollerant
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="invoice" title="Fakturering" chapter="3" v-show="chapter === CHAPTERS.INVOICE">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.LODGING)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.GDPR)" :disabled="!isInvoiceComplete">
						Nästa
					</BaseButton>
				</template>

				<div class="grid grid-cols-2 gap-5">
					<BaseInput type="email" v-model="invoice.email_billing_recipient">
						E-post fakturamottagare
					</BaseInput>

					<BaseInput type="text" v-model="invoice.billing_reference">
						Referens
					</BaseInput>

					<BaseInput type="email" v-model="invoice.email_billing_responsible">
						E-post ansvarig
					</BaseInput>

					<BaseInput type="text" v-model="invoice.billing_note">
						Fakturamärkning
					</BaseInput>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="gpdr" title="Behandling av uppgifter" chapter="4" v-show="chapter === CHAPTERS.GDPR">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.INVOICE)">
						Föregående
					</BaseButton>
					<BaseButton type="button" @click="changeChapter(CHAPTERS.PREVIEW)">
						Nästa
					</BaseButton>
				</template>

				<div class="grid grid-cols-3 gap-5">
					<div class="text-sm">
						<label for="hide_personal_info" class="flex items-center gap-2">
							<input type="checkbox" id="hide_personal_info" v-model="gdpr.hide_personal_info">
							Dölj mina personuppgifter
						</label>
					</div>

					<div class="text-sm">
						<label for="hide_contact_info" class="flex items-center gap-2">
							<input type="checkbox" id="hide_contact_info" v-model="gdpr.hide_contact_info">
							Dölj mina kontaktuppgifter i deltagarlistan
						</label>
					</div>

					<div></div>

					<div class="text-sm">
						<label for="accepts_marketing" class="flex items-center gap-2">
							<input type="checkbox" id="accepts_marketing" v-model="gdpr.accepts_marketing">
							Jag godkänner att min adress används i reklamsyfte
						</label>
					</div>
				</div>
			</ActivityApplicationSection>

			<ActivityApplicationSection id="preview" title="Granska och bekräfta" chapter="5" v-show="chapter === CHAPTERS.PREVIEW">
				<template #cta>
					<BaseButton type="button" transparent @click="changeChapter(CHAPTERS.GDPR)">
						Föregående
					</BaseButton>
					<BaseButton type="submit">
						Bekräfta anmälan
					</BaseButton>
				</template>

				<div class="flex flex-col gap-20">
					<div class="grid grid-cols-3 gap-y-10 gap-x-5">
						<div>
							<h5>Namn</h5>
							<p>{{ user.first_name }} {{ user.last_name }}</p>
						</div>

						<div>
							<h5>Personummer</h5>
							<p>{{ user.ssn }}</p>
						</div>

						<div></div>

						<div>
							<h5>E-postadress</h5>
							<p>{{ user.email }}</p>
						</div>

						<div>
							<h5>Telefonnummer</h5>
							<p>{{ user.telephone }}</p>
						</div>

						<div>
							<h5>Roll</h5>
							<p>Deltagare</p>
						</div>
					</div>

					<div v-if="isLodgingComplete">
						<h5>Logi</h5>
						<p>{{ lodging.lodging }}</p>
					</div>

					<div v-if="isFoodComplete">
						<h5>Kost</h5>
						<p>{{ lodging.food }}</p>
					</div>

					<div class="grid grid-cols-3 gap-5" v-if="isInvoiceComplete">
						<div v-if="invoice.email_billing_recipient.length">
							<h5>E-post fakturamottagare</h5>
							<p>{{ invoice.email_billing_recipient }}</p>
						</div>

						<div v-if="invoice.email_billing_responsible.length">
							<h5>E-post ansvarig</h5>
							<p>{{ invoice.email_billing_responsible }}</p>
						</div>
					</div>
				</div>
			</ActivityApplicationSection>
		</form>
	</main>
</template>

<script>
import {mapGetters} from 'vuex'
import ActivityApplicationSection from '@/modules/activity/components/ActivityApplicationSection'
import ApplicationService from '@/core/services/ApplicationService'
import basicApiCall from '@/core/helpers/basicApiCall'
import {validateEmail} from '@/core/helpers/validation'

const CHAPTERS = {
	PERSONAL: 'PERSONAL',
	LODGING: 'LODGING',
	INVOICE: 'INVOICE',
	GDPR: 'GDPR',
	PREVIEW: 'PREVIEW',
}

export default {
	name: 'ActivityApplication',
	components: {
		ActivityApplicationSection,
	},
	data: () => ({
		CHAPTERS,
		chapter: CHAPTERS.PERSONAL,
		personal: {
			ssn: '',
			first_name: '',
			last_name: '',
			email: '',
			telephone: '',
		},
		lodging: {
			lodging: '',
			food: '',
			vegan: false,
			vegetarian: false,
			lactose: false,
			gluten: false,
		},
		invoice: {
			email_billing_recipient: '',
			billing_reference: '',
			email_billing_responsible: '',
			billing_note: '',
		},
		gdpr: {
			hide_personal_info: false,
			hide_contact_info: false,
			accepts_marketing: false,
		},
	}),
	computed: {
		...mapGetters({
			user: 'AuthStore/user',
			activity: 'ActivityStore/activity',
		}),
		isPersonalComplete() {
			const isGeneralInfoComplete = this.personal.ssn.length > 9
				&& this.personal.last_name.length
				&& this.personal.last_name.length
				&& this.personal.email.length
				&& this.personal.telephone.length > 9

			const isEmailValid = this.validateEmail(this.personal?.email)

			return isGeneralInfoComplete && isEmailValid
		},
		isInvoiceComplete() {
			const hasEmailBillingRecipient = this.invoice.email_billing_recipient.length
			const hasEmailBillingResponsible = this.invoice.email_billing_responsible.length

			let isEmailBillingRecipientValid = true
			let isEmailBillingResponsibleValid = true

			if (hasEmailBillingRecipient) {
				isEmailBillingRecipientValid = this.validateEmail(this.invoice.email_billing_recipient)
			}

			if (hasEmailBillingResponsible) {
				isEmailBillingResponsibleValid = this.validateEmail(this.invoice.email_billing_responsible)
			}

			return isEmailBillingRecipientValid && isEmailBillingResponsibleValid
		},
		isFoodComplete() {
			return this.lodging.food.length
		},
		isLodgingComplete() {
			return this.lodging.lodging.length
		},
	},
	methods: {
		validateEmail,
		loadLoggedInUserInformation() {
			if (this.user?.id) {
				this.personal = {
					ssn: this.user.ssn,
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					email: this.user.email,
					telephone: this.user.telephone,
				}
			}
		},
		changeChapter(chapter) {
			this.chapter = chapter
		},
		chaptersLength() {
			return Object.keys(this.CHAPTERS).length
		},
		chapterNumber(chapter) {
			return Object.keys(this.CHAPTERS).findIndex(c => c === chapter) + 1
		},
		async submitListener() {
			const entity = {
				activity_id: this.activity.id,
				...this.personal,
				...this.lodging,
				...this.invoice,
				...this.gdpr,
			}

			const {data, status} = await basicApiCall(ApplicationService.create(entity))
			if (status === 201) {
				alert(`Anmälan skapades för ${data.first_name} ${data.last_name}`)
			}
		},
	},
	created() {
		this.loadLoggedInUserInformation()
	},
}
</script>

<style scoped>
.chapter-wrapper {
	@apply flex gap-5 items-center;
}

.chapter-circle {
	@apply h-10 w-10 bg-gray-300 rounded-full grid place-items-center;
	@apply transition-all duration-500;
}

.chapter-text {
	@apply text-gray-400 font-bold text-sm;
}

.chapter-line {
	@apply h-2 w-12 bg-gray-300 rounded-full;
}

.active {
	@apply bg-primary;
}

.active span {
	@apply text-white;
}
</style>
